import { MUTATION_TYPES } from './types';

export const actions = {
  setLoading({ commit }, isLoading) {
    commit(MUTATION_TYPES.SET_LOADING, isLoading);
  },

  showToast({ commit }, payload) {
    commit(MUTATION_TYPES.SET_NOTIFICATION, payload);
  },
};
