import { getUser, login as _login, logout as _logout } from '@/core/auth';
import types from './types';
import {
  getAdditionalProfileInfo,
} from '@/services/api/user.api.js';

export default {
  async init(context) {
    const { state, commit, dispatch } = context;
    try {
      const userStored = sessionStorage.getItem('user');
      if (userStored) {
        commit(types.SET_USER, JSON.parse(userStored));
      } else {
        const user = await getUser();
        commit(types.SET_USER, user);
        sessionStorage.setItem('user', JSON.stringify(user));
      }
    
      if (state.user && !state.permissions)
      {

        const { permissions } = await getAdditionalProfileInfo();        
    
        if (permissions) {
          
          commit(types.SET_PERMISSIONS, permissions);
          sessionStorage.setItem(
            'user-permissions',
            JSON.stringify(permissions)
          );
        }
      }        
    } catch (e) {
      dispatch('clearSession');
    }
  },

  async clearSession({ commit }) {
    commit(types.SET_USER, null);
    sessionStorage.removeItem('user');
    sessionStorage.removeItem('user-permissions');
    sessionStorage.clear();
  },

  login() {
    _login();
  },

  logout({ state, dispatch }) {
    let logoutId = state.user.profile.sub;
    _logout();
    dispatch('clearSession').then(function() {
      /* Señor FORCE */
      let urlLogout = process.env.VUE_APP_URL_AUTH;
      urlLogout += process.env.VUE_APP_ACR_URL_LOGOUT;
      urlLogout += '/?logoutid=' + logoutId;
      urlLogout +=
        '&redirectUrl=' + process.env.VUE_APP_URL_AUTH_POST_LOGOUT_REDIRECT_URI;
      window.location.replace(urlLogout);
    });
  },

  async updateUserGeneralInfo({ commit, state }, userGeneralInfo) {
    commit('UPDATE_USER_GENERAL_INFO', userGeneralInfo);
    sessionStorage.setItem('user', JSON.stringify(state.user));
  },
};
