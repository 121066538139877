const _env = process.env;

export const NODE_ENV = _env.NODE_ENV;

export const BASE_URL = _env.BASE_URL;

export const AUTH = {
  AUTHORITY: _env.VUE_APP_URL_AUTH,
  CLIENT_ID: _env.VUE_APP_CLIENT_ID,
  POST_LOGOUT_REDIRECT_URI: _env.VUE_APP_URL_AUTH_POST_LOGOUT_REDIRECT_URI,
  REDIRECT_URI: _env.VUE_APP_REDIRECT_URI,
  RESPONSE_TYPE: _env.VUE_APP_RESPONSE_TYPE,
  SCOPE: _env.VUE_APP_SCOPE,
  SILENT_REDIRECT_URI: _env.VUE_APP_SILENT_REDIRECT,
  ACR_VALUES: _env.VUE_APP_ACR_VALUES,
};

export const API = {
  BASE_URL: _env.VUE_APP_URL_API,
  TIMEOUT: 30000,
};

export const ACR_URL_LOGOUT = _env.VUE_APP_ACR_URL_LOGOUT;
export const ACR_VALUES = _env.VUE_APP_ACR_VALUES;
export const DEFAULT_PAGE_SIZE = _env.VUE_APP_DEFAULT_PAGE_SIZE;
export const SESSION_TIMEOUT = _env.VUE_APP_SESSION_TIMEOUT;
export const VUE_APP_RECAPTCHA_SITE_KEY = _env.VUE_APP_RECAPTCHA_SITE_KEY;
export const DASHBOARD_URL = _env.VUE_APP_DASHBOARD_URL;
