import types from './types';

export default {
  [types.SET_USER](state, user) {
    state.user = user;
  },

  [types.SET_USER_LOGOUT](state) {
    state.isLoggingOut = true;
    sessionStorage.setItem('user-is-logging-out', JSON.stringify(true));
  },
  [types.SET_PERMISSIONS](state, permissions) {
    state.permissions = permissions;
  },
  ['UPDATE_USER_GENERAL_INFO'](state, userGeneralInfo) {
    if (!state.user?.profile || !userGeneralInfo) {
      return;
    }
    state.user.profile.firstname = userGeneralInfo.firstname;
    state.user.profile.middlename = userGeneralInfo.middlename;
    state.user.profile.lastpaternalname = userGeneralInfo.lastpaternalname;
    state.user.profile.lastpaternalname = userGeneralInfo.lastmaternalname;
  },
};
