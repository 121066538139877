import { CalendarEventApi } from '@/api/calendar-event.api';
import applications from '@/utils/constants/applications';

const api = new CalendarEventApi();

const state = () => ({
  categories: [],
  dailyEvents: [],
  events: [],
});

const getters = {
  getEventById: (state) => (id) => {
    return state.events.find((event) => event.id == id);
  },
  getEventsByMonth: (state) => (month) => {
    return state.events.find((event) => event.month == month);
  },
  getCategories: (state) => {
    return state.categories;
  },
  getEvents: (state) => {
    return state.events;
  },
};

const mutations = {
  setEvents(state, events) {
    state.events = events;
  },

  setDailyEvents(state, dailyEvents) {
    state.dailyEvents = dailyEvents;
  },

  setCategories(state, categories) {
    state.categories = categories;
  },
};

const actions = {
  async getEvents({ commit }, params) {
    const events = await api.getEvents(params);
    commit('setEvents', events);
  },

  async getDailyEvents({ commit }) {
    const dailyEvents = await api.getDailyEvents(applications.Parent);
    commit('setDailyEvents', dailyEvents);
  },

  async getCategories({ commit }) {
    const categories = await api.getCategories();
    commit('setCategories', categories);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
