import { getUser } from '@/core/auth';

export const authHandler = async (config) => {
  // TODO: Implementacion correcta usando el SessionManger (Periodo de prueba)
  let user = await getUser();
  if (user != undefined) {
    config.headers.Authorization = `${user.token_type} ${user.access_token}`;
  } else {
    config.headers.Authorization = undefined;
  }
};
