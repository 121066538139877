import { http } from '@/core/api/';

const BaseUrl = '/api/account';

export const getAdditionalProfileInfo = async () => {
  try {    
    const { data } = await http.get(`${BaseUrl}/getprofileinformation/`);
    if (data) {
      const { companies, permissions, user } = data;

      return {
        companies: (companies || []).map(
          ({
            id,
            name,
            socialSecurityNo,
            isMainCompany,
            providerTypeId,
            providerTypeName,
          }) => ({
            id,
            name,
            ssn: socialSecurityNo,
            main: isMainCompany,
            providerTypeId,
            providerTypeName,
          })
        ),
        permissions,
        userProfile: user,
      };
    }

    return null;
  } catch {
    return null;
  }
};
