import { MUTATION_TYPES } from './types';

export const mutations = {
  [MUTATION_TYPES.SET_LOADING](state, isLoading) {
    state.isLoading = isLoading;
  },

  [MUTATION_TYPES.SET_NOTIFICATION](state, { message, type, showed }) {
    if (showed == true) {
      state.notification.notification.showed = true;
    } else {
      state.notification.message = message;
      state.notification.type = type;
      state.notification.showed = false;
      switch (type) {
        case 'success':
          state.notification.title = 'Proceso exitoso.';
          break;
        case 'error':
          state.notification.title = 'Error.';
          break;
      }
    }
  },
};
