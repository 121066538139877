export default {
  setProperty(state, { propertyName, value = null }) {
    state[propertyName] = value;
  },
  GetActionMessage(state, newValue) {
    state.actionMessage = newValue;
  },
  //Student Methods:
  setOldSelectedStudent(state, oldSelectedStudent) {
    state.selectedStudent = oldSelectedStudent;
    state.studentId = oldSelectedStudent.studentId;
    state.oldstudentId = oldSelectedStudent.studentId;
  },
  //Pei Methods:
  // TODO: Change because don't save in store information to pass another view
  setProvisionalRemedyRequestRef(state, newProvisionalRemedyRequestRef) {
    state.ProvisionalRemedyRequestRef = newProvisionalRemedyRequestRef;
  },
  setProvisionalRemedyRequest(state, newProvisionalRemedyRequest) {
    state.ProvisionalRemedyRequest = newProvisionalRemedyRequest;
  },
  // END TODO
  setFiltersFromProvisionalRemedyRequestRef(
    state,
    newFiltersFromProvisionalRemedyRequestRef
  ) {
    state.FiltersFromProvisionalRemedyRequest = newFiltersFromProvisionalRemedyRequestRef;
  },
  setSelectedPei(state, newSelectedPei) {
    state.peiSelected = newSelectedPei;
  },
  setSelectedPlacementCommitte(state, newPlacementCommitte) {
    state.placementCommittee = newPlacementCommitte;
  },
  setSelectedTransitionServicesFlag(state, newTransitionServicesFlag) {
    state.pei.transitionServicesFlag = newTransitionServicesFlag;
  },
  setSelectedPeiStatus(state, newSelectedPeiStatus) {
    state.pei.peiStatusCode = newSelectedPeiStatus;
  },
  setSelectedPeiStatusDescription(state, newSelectedPeiStatusDescription) {
    state.pei.peiStatusCodeDescription = newSelectedPeiStatusDescription;
  },
  setSelectedSignatureParticipant(state, singatureParticipant) {
    state.singatureParticipant = singatureParticipant;
  },
  setSelectedPeiEvaluationDateId(state, peiEvaluationDateId) {
    state.pei.peiEvaluationDateId = peiEvaluationDateId;
  },
  setSelectedPeiConfigUnilateralFlag(state, newConfigUnilateralFlag) {
    state.pei.configUnilateralFlag = newConfigUnilateralFlag;
  },
  setSelectedPeiConfigExtendedSchoolYearFlag(state, extendedSchoolYearFlag) {
    state.pei.extendedSchoolYearFlag = extendedSchoolYearFlag;
  },
  setSelectedPeiConfigOtherServTechAssistEquipFlag(
    state,
    otherServTechAssistEquipFlag
  ) {
    state.pei.otherServTechAssistEquipFlag = otherServTechAssistEquipFlag;
  },
  setSelectedPeiLocationRejected(state, locationRejected) {
    state.pei.peiLocationRejected = locationRejected;
  },
  setSelectedPeiInfo(state, newPeiInfo) {
    state.pei = newPeiInfo;
  },
  setPeiHasAcademicAreas(state, hasAA) {
    state.peiHasAcademicAreas = hasAA;
  },
  setPeiHasAlternativeAcademicUbications(state, hasAAU) {
    state.peiHasAlternativeAcademicUbications = hasAAU;
  },
  setPreviousPeiId(state, previousPeiId) {
    state.pei.previousPeiId = previousPeiId;
  },
  //---- end Pei Methods
  setATSaveBtnDisable(state, isDisabled) {
    state.atSaveBtnDisabled = isDisabled;
  },
  setSelectedAt(state, newSelectedAt) {
    state.atSelected = newSelectedAt;
  },
  //Preliminary methods
  setSelectPreEvalAnswer(state, preEvaluationAnswer) {
    state.preEvaluationAnswer = preEvaluationAnswer;
  },
  setSelectRecentlyEvalBy(state, recentlyEvaluatedBy) {
    state.recentlyEvaluatedBy = recentlyEvaluatedBy;
  },
  setAtValidationTriggered(state, value) {
    state.atValidationTriggered = value;
  },
  SetStudentTherapies(state, value) {
    if (value != undefined) {
      if (value != null) {
        for (let ii = 0; ii < value.length; ii++) {
          value[ii].index = ii + 0;
          value[ii].Active = '';
          value[ii].selected = false;
          if (ii == 0) {
            value[ii].Active = 'active';
            value[ii].selected = true;
          }
        }
      }
    }
    state.studentTherapies = value;
  },
  // Tabs
  setSaveMethod(state, saveCallback) {
    state.tab.saveMethod = saveCallback;
  },
  setChangeInitEvaluationMethod(state, changeInitEvaluationCallback) {
    state.tab.changeInitEvaluationMethod = changeInitEvaluationCallback;
  },
  setSaveChange(state, saveCallback) {
    state.studentSave = saveCallback;
  },
  setResetOriginalDataMethod(state, resetOldDataCallback) {
    state.tab.resetOriginalDataMethod = resetOldDataCallback;
  },
  setOldStudent(state, resetOldDataCallback) {
    state.resetStudent = resetOldDataCallback;
  },
  setFormDirty(state, payload) {
    if (payload.resetMethod) state.tab.resetMethod = payload.resetMethod;
    if (!payload.value && state.tab.resetMethod) state.tab.resetMethod();

    state.tab.currentFormDirty = payload.value;
  },
  setSaveBtnVisibility(state, visibility) {
    state.tab.showSaveBtn = visibility;
  },
  setActiveATTab(state, saveFunc) {
    state.activeATTab = saveFunc;
  },
  setAtCompleted(state, value) {
    state.atCompleted = value;
  },
  // Affirmative Letters
  setSelectedPeiAffirmativeLetter(state, newSelectedPeiAffirmativeLetter) {
    state.peiAffirmativeLetterSelected = newSelectedPeiAffirmativeLetter;
  },
  SetServiceTypeId(state, typeId) {
    state.serviceTypeId = typeId;
  },
  setPeiValidationTriggered(state, value) {
    state.peiValidationTriggered = value;
  },
  // Set Pei Analisys Service
  setAnalysisServiceId(state, value) {
    state.analysisServiceId = value;
  },
  setPeiTransportationInfo(state, transportationInfo) {
    state.peiTransportationInfo = transportationInfo;
  },
  setSelectedAreaExtended(state, newAreaExtended) {
    state.areaExtendedSelected = newAreaExtended;
  },
  setSelectedEvidenceReportParams(state, evidenceReportParams) {
    state.rlvEvidenceReportParams = evidenceReportParams;
  },
  setSelectedPeiAcademicYearId(state, configAcademicYearId) {
    state.pei.academicYearId = configAcademicYearId;
  },
  setPeiControversies(state, controversies) {
    state.pei.controversies = controversies;
  },
  setPeiTab2Information(state, newValue) {
    state.peiTab2.peiId = state.peiSelected;
    state.peiTab2.evaluationAnalysisDate = newValue.evaluationAnalysisDate
      ? new Date(newValue.evaluationAnalysisDate)
      : null;
    state.peiTab2.evaluationTriannualDate = newValue.evaluationTriannualDate
      ? new Date(newValue.evaluationTriannualDate)
      : null;
    state.peiTab2.generalImpedimentDescription =
      newValue.generalImpedimentDescription;
  },
  setParentRouter(state, routerInfo) {
    state.parentsRouter.push(routerInfo);
  },
  setVisiblePeiSignSection(state, isVisible) {
    state.peiTab1.showSignSection = isVisible;
  },
  //Set Navigation Menu
  setNavigationToViewRoute(state, route) {
    state.navigateToViewRoute = route;
  },
  //Set Student PreRegistry
  setExit(state, val) {
    state.exit = val;
  },
  setSignSectionVisibles(state, value) {
    state.peiTab1.signSectionVisibles = value;
  },
  setWebServiceStudentSearchEnabled(state, val) {
    state.webServiceStudentSearchEnabled = val;
  },
  setSelectedStudentMustHaveRegistration(state, val) {
    state.selectedStudentMustHaveRegistration = val;
  },
  setRegister(state, val) {
    state.register = val;
  },
  setRegisterEvaluation(state, val) {
    state.register.evaluations = val;
  },
  setRegisterMethod(state, registerCallBack) {
    state.tab.registerMethod = registerCallBack;
  },
  SetPreregisterFlag(state, data) {
    state.flagPreregister = data;
  },
  setComponentValidated(state, payload) {
    payload.map((component) => {
      if (component != undefined) {
        state.componentsValidated[component.name] = component.isValid;
      }
    });
  },
  setIsNavOpen(state, value) {
    state.isNavOpen = value;
  },
  toggleNav(state) {
    state.isNavOpen = !state.isNavOpen;
  },
  setIsMobileDensity(state, value) {
    state.isMobileDensity = value;
  },
  setShowEditionCallaout(state, value) {
    state.showEditionCallaout = value;
  },
  setAfterConfirmAppointmentMethod(state, afterConfirmAppointmentCallback) {
    state.afterConfirmAppoinmentMethod = afterConfirmAppointmentCallback;
  },
  setUserHasValidRole(state, value) {
    state.userHasValidRole = value;
  },
  setMessageCount(state, value) {
    state.messageCount = value;
  },
};
