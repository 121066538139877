import { AUTH } from '@/config';
import ClockService from '@/services/clock.service';
import { UserManager, WebStorageStateStore } from 'oidc-client';

const CHILD_LINK_ID_KEY = 'childlinkid';
const searchParams = new URLSearchParams(window.location.search);
const childLinkId = searchParams.get(CHILD_LINK_ID_KEY);

const getChildLinkId = () => {
  if (childLinkId) {
    return `${CHILD_LINK_ID_KEY}:${childLinkId}`;
  } else {
    return '';
  }
};

const userManager = new UserManager({
  authority: AUTH.AUTHORITY,
  automaticSilentRenew: true,
  client_id: AUTH.CLIENT_ID,
  filterProtocolClaims: true,
  post_logout_redirect_uri: AUTH.POST_LOGOUT_REDIRECT_URI,
  redirect_uri: AUTH.REDIRECT_URI,
  response_type: AUTH.RESPONSE_TYPE,
  scope: AUTH.SCOPE,
  silent_redirect_uri: AUTH.SILENT_REDIRECT_URI,
  userStore: new WebStorageStateStore({ store: window.sessionStorage }),
  clockService: new ClockService(),
  acr_values: `${AUTH.ACR_VALUES} ${getChildLinkId()}`.trim(),
});

const buildParentAccountAuthUrl = (linkId) => {
  const queryParams = {
    client_id: AUTH.CLIENT_ID,
    redirect_uri: AUTH.REDIRECT_URI,
    response_type: AUTH.RESPONSE_TYPE,
    scope: AUTH.SCOPE,
    code_challenge: process.env.VUE_APP_CODE_CHALLENGE,
    code_challenge_method: process.env.VUE_APP_CODE_CHALLENGE_METHOD,
    acr_values: userManager._settings._acr_values,
    response_mode: 'query',
  };
  var returnUrl = Object.keys(queryParams)
    .map((key) => {
      return (
        encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
      );
    })
    .join('&');
  return `${userManager._settings._authority}/AccountParent?childLinkId=${linkId}&returnUrl=/connect/authorize/callback?${returnUrl}`;
};

if (childLinkId) {
  window.location.href = buildParentAccountAuthUrl(childLinkId);
}

export const getUser = () => {
  return userManager.getUser();
};

export const login = () => {
  return userManager.signinRedirect();
};

export const logout = () => {
  userManager.removeUser();
};

export const startup = () => {
  return userManager
    .getUser()
    .then((user) => {
      if (user == null) {
        userManager.signinRedirect();
        return false;
      }
      return true;
    })
    .catch(() => {
      return false;
    });
};

export const getAccessDeniedAuthURL = () => {
  const queryParams = {
    client_id: AUTH.CLIENT_ID,
    redirect_uri: AUTH.REDIRECT_URI,
    response_type: AUTH.RESPONSE_TYPE,
    scope: AUTH.SCOPE,
    code_challenge: process.env.VUE_APP_CODE_CHALLENGE,
    code_challenge_method: process.env.VUE_APP_CODE_CHALLENGE_METHOD,
    acr_values: process.env.VUE_APP_ACR_VALUES,
    response_mode: 'query',
  };
  const returnUrl = Object.keys(queryParams)
    .map((key) => {
      return (
        encodeURIComponent(key) + '=' + encodeURIComponent(queryParams[key])
      );
    })
    .join('&');
  const getBaseName = () => {
    const value = (AUTH.AUTHORITY.split('/')[3] ?? '').trim();
    if (value) {
      return `/${value}`;
    }
    return value;
  };
  const baseName = getBaseName();
  const encodedReturnUrl = encodeURIComponent(
    `${baseName}/connect/authorize/callback?${returnUrl}`
  );
  return `${process.env.VUE_APP_URL_AUTH}/account/withoutpermission?ReturnUrl=${encodedReturnUrl}`;
};
