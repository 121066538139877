import { mutations } from './mutations';
import { actions } from './actions';
import { state } from './state';
import { getters } from './getters';

export const name = '$_app';

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
