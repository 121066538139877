import axios from 'axios';
import { API, NODE_ENV } from '@/config';
import { requestInterceptor, responseInterceptor } from './interceptor';

const http = axios.create({
  baseURL: API.BASE_URL,
  timeout: NODE_ENV === 'production' ? API.TIMEOUT : undefined,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

http.interceptors.request.use(
  requestInterceptor.onFulfilled,
  requestInterceptor.onRejected
);

http.interceptors.response.use(
  responseInterceptor.onFulfilled,
  responseInterceptor.onRejected
);

export { http };
