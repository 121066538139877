import { authHandler } from './auth-handler';
import { requestErrorHandler, responseErrorHandler } from './error-handler';

export const requestInterceptor = {
  onFulfilled: async (config) => {
    await authHandler(config);
    return config;
  },

  onRejected: (error) => {
    requestErrorHandler(error);
    return error;
  },
};

export const responseInterceptor = {
  onFulfilled(response) {
    return response;
  },

  onRejected(error) {
    responseErrorHandler(error);
    return error;
  },
};
