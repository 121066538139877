import { http } from '@/core/api';

class ParentApi {
  async getChildren() {
    try {
      const { data } = await http.get('api/parent/children');
      return data;
    } catch {
      return null;
    }
  }

  async getChild(studentSieId) {
    try {
      const { data } = await http.get(`api/parent/children/${studentSieId}`);
      return data;
    } catch {
      return null;
    }
  }
}

export { ParentApi };
