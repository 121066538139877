import { http } from '@/core/api';

class CalendarEventApi {
  async getCategories() {
    try {
      const { data } = await http.get('api/calendarEvent/categories');
      return data;
    } catch {
      return null;
    }
  }

  async getEvents({ year, month, week, day, categoryId, applicationId }) {
    try {
      const { data } = await http.get(`api/calendarEvent/events/${year}`, {
        params: {
          month,
          week,
          day,
          categoryId,
          applicationId,
        },
      });
      return data;
    } catch {
      return null;
    }
  }

  async getEvent(id) {
    try {
      const { data } = await http.get(`api/calendarEvent/${id}`);
      return data;
    } catch {
      return null;
    }
  }

  async getDailyEvents(applicationId) {
    try {
      const { data } = await http.get('api/calendarEvent/dailyevents', {
        params: {
          applicationId,
        },
      });
      return data;
    } catch {
      return null;
    }
  }
}

export { CalendarEventApi };
