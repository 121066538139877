import Vue from 'vue';
import Vuex from 'vuex';
import { NODE_ENV } from '@/config';
import { AppModule, UserModule, modulesName } from '@/modules';

export { modulesName };

import state from './state';
import getters from './getters';
import mutations from './mutations';

//modules
import students from './modules/students';
import events from './modules/events';

Vue.use(Vuex);

const debug = NODE_ENV !== 'production';
const store = new Vuex.Store({
  strict: debug,
  state,
  mutations,
  getters,
  modules: {
    [modulesName.appModuleName]: AppModule,
    [modulesName.userModuleName]: UserModule,
    //
    $_students: students,
    $_events: events,
  },
});

if (module.hot) {
  module.hot.accept(['./modules/students', './modules/events'], () => {
    store.hotUpdate({
      modules: {
        $_students: require('./modules/students').default,
        $_events: require('./modules/events').default,
      },
    });
  });
}

export default store;
