import { ParentApi } from '@/api/parent.api';

const api = new ParentApi();

const state = () => ({
  students: [],
});

const getters = {
  getStudentById: (state) => (id) => {
    return state.students.find((student) => student.studentSieId == id);
  },
};

const mutations = {
  setStudents(state, students) {
    state.students = students;
  },
};

const actions = {
  async getStudents({ commit }) {
    const students = await api.getChildren();
    commit('setStudents', students);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
