const state = () => ({
  user: sessionStorage.getItem('user')
    ? JSON.parse(sessionStorage.getItem('user'))
    : null,
  permissions: sessionStorage.getItem('user-permissions')
    ? JSON.parse(sessionStorage.getItem('user-permissions'))
    : null,
  isLoggingOut: sessionStorage.getItem('user-is-logging-out')
    ? JSON.parse(sessionStorage.getItem('user-is-logging-out'))
    : null,
});

export default state;
