export default {
  //Bad Place for This information (session Storage is proper place) [Refactory]
  token: sessionStorage.getItem('user-token')
    ? JSON.parse(sessionStorage.getItem('user-token'))
    : {
        code: '',
        id_token: '',
        access_token: '',
        token_type: '',
        expires_in: '',
        expires_time: '',
        scope: '',
        session_state: '',
        refresh_token: '',
      },
  lastUserInteractionDate: new Date().getTime(),
  isLoggingOut: sessionStorage.getItem('isLoggingOut')
    ? JSON.parse(sessionStorage.getItem('isLoggingOut'))
    : false,

  //This is Search Component
  navigateToViewRoute: '',
  navigateToViewDisplay: false,
  //This Module  [Refactory] (Temporal Fix) [The real fix take more time -_- ]
  userProfile: sessionStorage.getItem('user-profile')
    ? JSON.parse(sessionStorage.getItem('user-profile'))
    : {
        user: {
          role: '',
          roleid: '',
          givenname: '',
          firstname: '',
          lastpaternalname: '',
          userlocation: '',
          activeflag: '',
          middlename: '',
          isapproved: '',
          islockedout: '',
          aduserflag: '',
          sieuserflag: '',
          idp: '',
          sub: '',
          name: '',
          email: '',
          lastLogin: '',
          permissions: [],
          centerId: null,
        },
      },

  webServiceStudentSearchEnabled: false,
  selectedStudentMustHaveRegistration: false,
  studentId: 0,
  oldstudentId: 0,
  searchedStudentHistory: [],
  //Student this information is module
  ProvisionalRemedyRequest: {
    name: null,
    sie: null,
    evaluationType: null,
    discipline: null,
    applicationDate: null,
    studentReferralId: null,
    disciplineTypeId: null,
    AcademicYearId: null,
  },
  placementCommittee: null,
  //Pei
  ProvisionalRemedyRequestRef: {
    name: null,
    sie: null,
    disciplineName: null,
    modalityTypeName: null,
    frequecy: null,
    duration: null,
  },
  peiSelected: null,
  pei: {
    peiStatusCode: null,
    peiStatusCodeDescription: null,
    peiLocationRejected: null,
    configUnilateralFlag: 0,
    prescholarFlag: 0,
    extendedSchoolYearFlag: 0,
    otherServTechAssistEquipFlag: 0,
    academicYearId: 0,
    previousPeiId: 0,
    // PEI controversy list.
    controversies: null,
    transitionServicesFlag: '0',
    ageAmt: null,
    checklistStatusCode: null,
  },
  peiHasAcademicAreas: false,
  peiHasAlternativeAcademicUbications: null,
  //At
  atSelected: 0,
  atSaveBtnDisabled: false,
  // Alert Modal for required selected student
  showRequiredStudentModal: false,
  //Tabs for selected evaluation answer
  preEvaluationAnswer: false,
  recentlyEvaluatedBy: 0,
  //for validations AT
  atValidationTriggered: {
    validation: false,
  },
  studentTherapies: [],
  tab: {
    saveMethod: function() {},
    registerMethod: function() {},
    changeInitEvaluationMethod: function() {},
    currentFormDirty: false,
    resetMethod: null,
    resetOriginalDataMethod: null,
    showSaveBtn: false,
  },
  peiTab1: {
    showSignSection: false,
    signSectionVisibles: [],
  },
  atCompleted: false,
  activeATTab: {
    Id: '',
    object: {},
  },
  // Pei Affirmative Letter
  peiAffirmativeLetterSelected: null,
  serviceTypeId: 0,
  regStudentId: 0,
  //for validations PEI
  peiValidationTriggered: {
    validation: false,
  },
  // Pei Analisys Services
  peiTransportationInfo: null,
  analysisServiceId: 0,
  areaExtendedSelected: null,
  parentsRouter: [],
  studentSave: function() {},
  resetStudent: function() {},
  peiTab2: {
    peiId: '',
    evaluationAnalysisDate: null,
    evaluationTriannualDate: null,
    generalImpedimentDescription: '',
  },
  exit: null,
  flagPreregister: [],
  componentsValidated: {},
  isNavOpen: true,
  isMobileDensity: false,
  rlvEvidenceReportParams: null,
  postRegistryState: {},
  showEditionCallaout: false,
  afterConfirmAppoinmentMethod: function() {},
  userHasValidRole: null,
  messageCount: 0,
};
