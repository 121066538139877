export const state = {
  isLoading: false,

  notification: {
    title: '',
    message: '',
    type: 'sucess',
    showed: true,
  },
};
