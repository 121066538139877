export default {
  authenticated: ({ user }) => (user === null ? false : !user.expired),

  accessToken: ({ user }) => (user === null ? null : user.access_token),

  tokenType: ({ user }) => (user === null ? null : user.token_type),

  expiresAt: ({ user }) => (user === null ? null : user.expires_at),

  role: ({ user }) => (user === null ? null : user.profile.role),

  fullProfile({ user }) {
    if (user != undefined && user.profile != undefined) {
      let userProfile = {
        ...user.profile,
        municipalityId: user.profile.municipalityid,
        districtId: user.profile.districtid,
        centerId: user.profile.centerid,
        roleId: user.profile.roleid,
      };
      Object.keys(userProfile).forEach(
        (key) => userProfile[key] === undefined && delete userProfile[key]
      );
      return userProfile;
    }
    return undefined;
  },

  profile({ user }) {
    if (user === null) {
      return null;
    }
    const { name, sub } = user.profile;
    return {
      id: sub,
      name,
    };
  },
  hasPermissions: ({ permissions }) => (_permissions) =>
    permissions && permissions.length
      ? permissions.some((permission) => _permissions.includes(permission))
      : false,
};
